.projects-container {
    background-color: #6E07F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: -90px; */
    padding-top: 85px;
}

.projects-container > .projects-intro > h2 {
    width: max-content;
    margin: 0 auto;
    color: whitesmoke;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -.5px;
    margin-bottom: 15px;
}

.projects-container > .projects-intro > p {
    color: white;
    font-size: 20px;
    letter-spacing: -.5px;
    text-align: center;
}

.projects-container > .projects-intro > p > a {
    text-decoration: none;
    color: #e98074;
}

.projects-container > .projects-intro > p > a:hover {
    color: #e85a4f;
}

.projects-div {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px 0;
    max-width: 1300px;
    padding: 40px 0;
    margin-bottom: 20px;
}

.one-project {
    border: 1.5px solid rgba(110, 7, 243, .4);
    border-radius: 25px;
    background-color: whitesmoke;
    width: 500px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 25px;
}

.projects-div > .one-project > h2 {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -.5px;
    font-weight: 500;
}

.projects-div > .one-project > .languages {
    font-size: 16.5px;
    font-weight: 100;
}

.projects-div > .one-project > .languages > span {
    color: #6E07F3;
    font-size: 18px;
    letter-spacing: -.3px;
    font-weight: 500;
}

.projects-div > .one-project > .caption {
    font-size: 18px;
    font-weight: 100;
    line-height: 22px;
}

.projects-div > .one-project > div:nth-child(1) {
    align-items: center;
}

.projects-div > .one-project > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.projects-div > .one-project > div > a {
    border-radius: 4px;
    color: #6E07F3;
    background-color: rgba(110, 7, 243, .2);
    width: max-content;
    height: 30px;
    padding: 10px 8px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    letter-spacing: -.3px;
    margin-top: 5px;
}

.projects-div > .one-project > div > a:hover {
    box-shadow: 0 1px 5px #6E07F3;
    cursor: pointer;
}

.projects-div > .one-project > div > a > .icon {
    margin-right: 5px;
    font-size: 25px;
    color: #6E07F3;
}

.projects-div > .one-project > div > img {
    border-radius: 12px;
    width: 50px;
    height: 50px;
}


.projects-div > .one-project > div > img:hover {
    cursor: pointer;
    box-shadow: 0 1px 5px #6E07F3;
}

/* ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~  */
/* Project Overlay Preview */

.overlay-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
}

.presently {
    transform: scale(1.05);
    margin-top: 10px;
    margin-bottom: 20px;
}

.cooklore {
    transform: scale(0.70);
    margin-top: -125px;
    margin-bottom: -120px;
}

.animecardmatchinggame {
    transform: scale(0.6);
    margin-top: -160px;
    margin-bottom: -140px;
}

.theofficetriviagame {
    transform: scale(0.7);
    margin-top: -160px;
    margin-bottom: -90px;
}

.overlay-content > .close-preview-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.overlay-content > div {
    width: max-content;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #fff;
}

.overlay-content > div > button {
    background: none;
    border: none;
    cursor: pointer;
}

.overlay-content > div > button > .imgBtn {
    pointer-events: none;
    width: 18px;
    height: 18px;

}

.overlay-content > div > button:nth-child(2){
    margin-left: 20px;
}

.modal-open {
    overflow: hidden; /* Prevents scroll on behind content */
  }



@media(max-width: 768px){
    .projects-container {
        padding-top: 60px;

    }

    .projects-container > .projects-intro {
        margin-bottom: 25px;
    }

    .projects-container > .projects-intro > p {
        text-wrap: balance;
        margin: 0 auto;
    }

    .projects-container > .projects-div {
        padding: 20px 10px;
    }

    .projects-container > .projects-div > .one-project {
        width: 80%;
    }

    .projects-div > .one-project > div > a > .icon {
        margin: 0 auto;
    }

    .presently {
        transform: scale(.95);
        margin-bottom: 100px;
    }
    
    .cooklore {
        transform: scale(0.55);
        margin-top: -150px;
        margin-bottom: -50px;
    }
    
    .animecardmatchinggame {
        transform: scale(0.2);
        margin-top: -400px;
    }
    
    .theofficetriviagame {
        transform: scale(0.28);
        margin-top: -400px;
    }

    .overlay-content > div {
        margin-top: -80px;
    }
    
}
